<template>
  <div>
    <vs-prompt title="Exportar para Excel" class="export-options" @accept="exportToExcel" accept-text="Exportar" :active.sync="configExportExcel.activePrompt">
        <vs-input v-model="configExportExcel.fileName" placeholder="Nome do arquivo ..." class="w-full" />
        <v-select v-model="configExportExcel.selectedFormat" :options="configExportExcel.formats" class="my-4" />        
    </vs-prompt>
     <vs-prompt title="Exportar Lista para Excel" class="export-options" @accept="exportListaVeiculosToExcel" accept-text="Exportar" :active.sync="configExportExcelListaVeiculos.activePrompt">
        <vs-input v-model="configExportExcelListaVeiculos.fileName" placeholder="Nome do arquivo ..." class="w-full" />
        <v-select v-model="configExportExcelListaVeiculos.selectedFormat" :options="configExportExcelListaVeiculos.formats" class="my-4" />        
    </vs-prompt>
    <vs-popup title="Procedimento de tratativa de evento" :active.sync="popupProcedimento.exibir">
       <h6>Procedimento </h6>
       <p style="white-space: pre-line"><small>{{popupProcedimento.procedimento}} </small></p>
       <hr/>
       <h6 class="mt-4">Contatos </h6>
       <p><small v-if="popupProcedimento.contato1">Contato 1: {{popupProcedimento.contato1}} </small></p>
       <p><small v-if="popupProcedimento.contato2">Contato 2: {{popupProcedimento.contato2}} </small></p>       
       <p><small v-if="popupProcedimento.contatoPanico1">Contato 1 Emergência: {{popupProcedimento.contatoPanico1}} </small></p>
       <p><small v-if="popupProcedimento.contatoPanico2">Contato 2 Emergência: {{popupProcedimento.contatoPanico2}} </small></p>
       <p><small v-if="popupProcedimento.contatoPanico3">Contato 3 Emergência: {{popupProcedimento.contatoPanico3}} </small></p>
    </vs-popup> 
    <vs-popup title="Informações do Cliente e Veículo" :active.sync="popupInformacoes.exibir" v-if="popupInformacoes.veiculo">
       <h6>Veículo </h6>
       <vs-row class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"> 
              <small>Marca: <span class="font-medium">{{popupInformacoes.veiculo.marca}} </span></small>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"> 
              <small>Modelo: <span class="font-medium">{{popupInformacoes.veiculo.modelo}} </span></small>
          </vs-col>
       </vs-row>
       <vs-row class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"> 
              <small>Placa: <span class="font-medium">{{popupInformacoes.veiculo.placa}} </span></small>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"> 
              <small>Condutor: <span class="font-medium">{{popupInformacoes.veiculo.condutor}} </span></small>
          </vs-col>          
       </vs-row>
       <vs-row class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"  v-if="popupInformacoes.veiculo.telefoneCondutor"> 
              <small>Telefones Condutor: <span class="font-medium"> {{popupInformacoes.veiculo.telefoneCondutor}} </span> </small>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"  v-if="popupInformacoes.veiculo.corVeiculo"> 
              <small>Cor: <span class="font-medium"> {{popupInformacoes.veiculo.corVeiculo}} </span> </small>
          </vs-col>          
       </vs-row>
       <vs-row class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"  v-if="popupInformacoes.veiculo.cpfCondutor"> 
              <small>CPF Condutor: <span class="font-medium"> {{popupInformacoes.veiculo.cpfCondutor}} </span> </small>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"  v-if="popupInformacoes.veiculo.cnhCondutor"> 
              <small>CNH Condutor: <span class="font-medium"> {{popupInformacoes.veiculo.cnhCondutor}} </span> </small>
          </vs-col>          
       </vs-row>
       <vs-row  class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6" v-if="popupInformacoes.veiculo.anoFabricacao"> 
              <small>Ano:  <span class="font-medium">{{popupInformacoes.veiculo.anoFabricacao}} </span> </small>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6" v-if="popupInformacoes.veiculo.labelVeiculo"> 
              <small >Label: <span class="font-medium">{{popupInformacoes.veiculo.labelVeiculo}} </span> </small>
          </vs-col>
       </vs-row>
       

       <h6 class="mt-4">Cliente </h6>
       <vs-row class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="12"> 
              <small>Nome: <span class="font-medium">{{popupInformacoes.veiculo.clienteNome}} </span></small>
          </vs-col>
       </vs-row>
       <vs-row class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="12"> 
              <small>CPF/CNPJ: <span class="font-medium">{{popupInformacoes.veiculo.clienteCPFCNPJ}} </span></small>
          </vs-col>
       </vs-row>
       <vs-row class="mt-2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"> 
              <small>Telefone: <span class="font-medium">{{popupInformacoes.veiculo.clienteTelefone}} </span></small>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-w="6"> 
              <small>Último acesso a plataforma: <span class="font-medium">{{popupInformacoes.veiculo.clienteDataHoraUltimoAcesso}} </span></small>
          </vs-col>
       </vs-row>
    </vs-popup>
    <vs-prompt :title="popMonitoramento.descricaoNovoStatus" :active.sync="popMonitoramento.exibir" v-if="popMonitoramento.exibir"
        @accept="enviarMonitoramento" :is-valid="isPopUpMonitoramentoValido"
        acceptText="OK" cancelText="Fechar">      
      <vs-textarea counter="400" class="mt-3 w-full" v-model="popMonitoramento.descricaoAcao" />                
    </vs-prompt> 
    <vs-popup :title="popUpHistoricoEventos.titulo" :active.sync="popUpHistoricoEventos.exibir" v-if="popUpHistoricoEventos.exibir">
       <vx-card class="mr-4 p-0">
       <div class="flex">
          <v-select  
              label="nome"                                                                 
              :options="popupListaVeiculosMonitoraveis.listaVeiculosMonitoraveis"                
              placeholder="Todos os veículos"
              v-model="popUpHistoricoEventos.veiculo"      
              :filter="pesquisarVeiculo"
              class="w-full vs-input--label">
              <template v-slot:option="option">                        
                  <span style='font-family: Verdana, sans-serif;'> {{ option.placa }} </span>
              </template>
              <template #selected-option="{ placa }">                          
                  <span style='font-family: Verdana, sans-serif;'> {{ placa }} </span>
              </template>
              <template #no-options="">
                  {{ $t('NenhumVeiculoEncontrado') }}
              </template>
          </v-select>  
          <flat-pickr v-model="popUpHistoricoEventos.dataHoraPanicoInicio" :config="configDatePicker" placeholder="Data Início"/>          
          <flat-pickr v-model="popUpHistoricoEventos.dataHoraPanicoFim" :config="configDatePicker" placeholder="Data Fim"/>
          <vs-button class="ml-2" icon="close" color="primary" @click="popUpHistoricoEventos.dataHoraPanicoInicio = null; popUpHistoricoEventos.dataHoraPanicoFim = null;"/>
          <vs-button class="ml-2" icon="search" color="primary" @click="fetchHistorico()" :disabled="!popUpHistoricoEventos.dataHoraPanicoInicio || !popUpHistoricoEventos.dataHoraPanicoFim" />
          <vx-tooltip text="Exportar para Excel">              
            <vs-button class="ml-2" icon="table_chart" color="success" @click="exibirPopUpExportarExcel" v-if="dadosDaGridHistorico && dadosDaGridHistorico.length > 0"></vs-button>
          </vx-tooltip> 
       </div>
       </vx-card>
       <vs-table :data="dadosDaGridHistorico" class="mt-2" pagination>
          <template slot="thead">
              <vs-th>#</vs-th>
              <vs-th>Placa</vs-th>
              <vs-th>Evento</vs-th>
              <vs-th>Ação</vs-th>            
              <vs-th>Monitor</vs-th>
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td width="10px"><small> {{tr.indice}} </small></vs-td>
                <vs-td><small>{{tr.placa}}</small></vs-td>                
                <vs-td><small><strong>{{tr.evento}}</strong></small> <br/> <small>Data/Hora: {{tr.dataHoraEvento}}</small> <br/><small>Tratado: {{tr.dataHora}}</small> </vs-td>                
                <vs-td><small>{{tr.acao}}</small> <br/> <small>{{tr.descricaoAcao}}</small></vs-td>
                <vs-td><small>{{tr.monitorPanico}}</small></vs-td>
              </vs-tr>
          </template>
       </vs-table>
    </vs-popup>
    <vs-popup title="Lista Veículos Monitoráveis" :active.sync="popupListaVeiculosMonitoraveis.exibir">
       <vs-table :data="popupListaVeiculosMonitoraveis.listaVeiculosMonitoraveis" class="tabelaNotificacoesComScroll mt-2" search hoverFlat stripe>
          <template slot="header">            
            <vs-button class="ml-2" icon="table_chart" color="success" @click="exibirPopUpExportarListaVeiculosExcel" v-if="popupListaVeiculosMonitoraveis.listaVeiculosMonitoraveis && popupListaVeiculosMonitoraveis.listaVeiculosMonitoraveis.length > 0">Exportar</vs-button>          
          </template>
          <template slot="thead">
              <vs-th>#</vs-th>
              <vs-th>Cliente</vs-th>
              <vs-th>Veiculo</vs-th>
              <vs-th>Placa</vs-th>
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td><small>{{indextr+1}}</small></vs-td>
                <vs-td><small>{{tr.clienteNome}}</small> <br/><small>{{tr.clienteCPFCNPJ}}</small></vs-td>
                <vs-td><small>{{tr.marca}} {{tr.modelo}} {{tr.labelVeiculo}}</small></vs-td>
                <vs-td><small>{{tr.placa}} </small></vs-td>
              </vs-tr>
          </template>
       </vs-table>
    </vs-popup> 
    <vs-popup title="Ações" :active.sync="popupAcoes.exibir" style="z-index: 43000 !important">
      <vs-row>
          <div class="w-full text-center mb-4" v-if="popupAcoes.evento"> 
            <h4 class="text-success"> {{popupAcoes.evento.placa}} {{popupAcoes.evento.hasOwnProperty('labelVeiculo') ?  ' / ' +  popupAcoes.evento.labelVeiculo : ''}} </h4>
            <h5> {{popupAcoes.evento.dataHoraPanico}} </h5>
            <h5 class="text-warning"> {{popupAcoes.evento.tipoEvento}} </h5>
            <h5> {{popupAcoes.evento.descricaoAviso}} </h5>             
          </div>

          <div class="w-full flex justify-between"> 
            <vs-button size="small" color="primary" type="filled" icon="description" @click="abrirPopUpProcedimento(popupAcoes.evento)">PROCEDIMENTO</vs-button>
          
            <vs-button size="small" color="primary" type="filled" icon="person" @click="abrirPopUpInformacoes(popupAcoes.evento)">INFORMAÇÕES</vs-button>
          
            <vs-button size="small" color="primary" type="filled" icon="view_in_ar" @click="abrirPopUpMonitoramento(popupAcoes.evento, 2, 'Monitoramento do evento')">MONITORAR</vs-button>
          
            <vs-button size="small" color="primary" type="filled" icon="search" @click="abrirPopUpHistorico(popupAcoes.evento)">HISTÓRICO</vs-button>

            <vs-button size="small" color="primary" type="filled" icon="pin_drop" @click="mostrarNoMapa(popupAcoes.evento)">VER NO MAPA</vs-button>
          </div>
          <div class="w-full flex justify-center mt-4" v-if="popupAcoes.evento"> 

            <vs-button class="mr-2" size="small" color="success" type="filled" icon="cancel" @click="abrirPopUpMonitoramento(popupAcoes.evento, 3, 'Cancelar evento')">CANCELAR</vs-button>
            <vs-button class="mr-2" size="small" color="success" type="filled" icon="done_outline" @click="abrirPopUpMonitoramento(popupAcoes.evento, 4, 'Encerrar evento')">ENCERRAR</vs-button>

            <vs-button class="mr-2" size="small" color="danger" type="filled" icon="lock" @click="bloquear(popupAcoes.evento)" v-if="popupAcoes.evento.possuiBloqueio">BLOQUEAR </vs-button>
            <vs-button size="small" color="warning" type="filled" icon="lock_open" @click="desbloquear(popupAcoes.evento)" v-if="popupAcoes.evento.possuiBloqueio">DESBLOQUEAR </vs-button>
          </div>

       </vs-row>
    </vs-popup>

    <div class="map50">            
        <GoogleMapConcept :mapa="map" ref="gmc"></GoogleMapConcept>                
    </div> 
    <div class="flex items-center mt-2">
        <vs-button size="small" color="primary" type="filled" @click="abrirPopUpListaVeiculosMonitoraveis">Veículos Monitorados: {{qtdVeiculosMonitorados}}</vs-button>
        <vs-button size="small" color="primary" type="filled" @click="abrirPopUpHistoricoPeriodo" class="ml-2">Histórico Monitoramento</vs-button>
        <p class="text-danger ml-4">Acionamento pânico: </p> <h6 class="ml-4"> {{qtdAvisosPanico }}</h6>  
        <p class="text-danger ml-4">Avisos:</p> <h6 class="ml-4">{{qtdAvisos }} </h6>
        <vs-button size="small" color="primary" type="filled" @click="abrirPopUpMonitoramentoLote" class="ml-4" v-if="itensSelecionados.length">Cancelar Selecionados</vs-button>
        <small class="text-small text-success ml-auto">Atualizado em:</small> <small class="ml-2" :class="{blink_me: isPiscar}">{{dataHoraUltimaAtualizacao}} </small>
    </div>

     <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-grid-table ag-grid-altura mt-1"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="[...dadosDaGrid]"
      @row-selected="onRowSelectedRegistro"  
      :rowHeight="60"
      :headerHeight="20"        
      rowSelection="single"
      colResizeDefault="shift"      
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      :getRowId="getRowId"
      >
    </ag-grid-vue>   
    
  </div>

</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import generico from "@/generico.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/concept/scss/tabelaBasica.scss'


const URL_SERVLET = "/MonitorarVeiculosEmPanico";

export default {
  components: {
    flatPickr,
    AgGridVue
  },
  data() {
    return {      
      popupAcoes : {
          exibir: false,
          evento: null
      },
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [       
            { headerName: '#',  valueGetter: "node.rowIndex + 1", width: 20 },  
            { headerName: 'Marca', field: 'modelo',
              cellRenderer: function(params) {
                return params.data.modelo + ' ' + params.data.placa + (params.data.hasOwnProperty('labelVeiculo') ?  ' / ' +  params.data.labelVeiculo : '') 
                + '<br/> <i style="font-size: 12px;" class="material-icons">person</i> <span style="font-size: 10px; color:orange"> ' + params.data.condutor + ' </span> ' 
                + '<br/> <i style="font-size: 12px;" class="material-icons">pin_drop</i> <span style="font-size: 10px;"> ' +  params.data.enderecoAtual + '</span>';
              },
             },           
            { headerName: 'Status', field: 'placa', width: 60,
              cellRenderer: function(params) {
               let tr = params.data;
               return ( tr.ignicao === 'LIGADO' && tr.velocidadeAtual > 0  ?  ' <span class="text-success"><i style="font-size: 12px;" class="material-icons" title="Veículo ligado  - voltagem '+ tr.ultimaVoltagem +'v" >fiber_manual_record</i> </span>' : '')  +
               ( tr.ignicao === 'LIGADO' && tr.velocidadeAtual === 0 ?  '<span class="text-warning"><i style="font-size: 12px;" class="material-icons" title="Veículo ligado e parado  - voltagem '+ tr.ultimaVoltagem +'v">fiber_manual_record</i> </span>' : '')  +
               ( tr.ignicao === 'DESLIGADO' ?  '<span class="text-danger"><i style="font-size: 12px;" class="material-icons" title="Veículo desligado  - voltagem '+ tr.ultimaVoltagem +'v">fiber_manual_record</i> </span>' : '')   +
               
               ( tr.possuiBloqueio && tr.isBloqueado ?  '<span class="text-danger"><i style="font-size: 12px;" class="material-icons" title="Veículo bloqueado">lock</i> </span>' : '')   +
               ( tr.possuiBloqueio && !tr.isBloqueado ?  '<span class="text-success"><i style="font-size: 12px;" class="material-icons" title="Veículo desbloqueado">unlock</i> </span>' : '') +

               ( tr.status === 'ON' ?  '<span class="text-success"><i style="font-size: 12px;" class="material-icons" title="Comunicação atualizada">wifi</i> </span>' : '')  +
               ( tr.status === 'OFF' ?  '<span class="text-danger"><i style="font-size: 12px;" class="material-icons" title="Comunicação desatualizada há ' + tr.tempoSemGPRS+ '">wifi</i> </span>' : '')  +

               ( tr.semAlimentacaoExterna ?  '<span class="text-danger"><i style="font-size: 12px;" class="material-icons" title="Sem alimentação externa">flash_off</i> </span>' : '')  
              }
            },
            { headerName: 'Evento', field: 'dataHoraPanico', 
              cellRenderer: function(params) {
                return params.data.dataHoraPanico + '<br/> <span style="font-size: 10px; color:orange"> ' + params.data.tipoEvento + ' </span> <br/> ' +  params.data.descricaoAviso;
              }
            },
            { headerName: 'Monitoramento', field: 'placa', width: 130,
              cellRenderer: function(params) {
                return ( params.data.statusPanico !== 'NÃO ATIVO' ?  params.data.usuarioMonitorPanico : '') +
                ( params.data.statusPanico !== 'NÃO ATIVO' ?  '<br/>' + params.data.dataHoraUltimoMonitoramentoPanico : '') +
                ( params.data.statusPanico === 'ATIVO' ?  '<br/> <span class="text-success"><i class="material-icons">check_circle</i> </span>' : '')  +
                ( params.data.statusPanico === 'NÃO ATIVO' ?  '<br/> <span class="text-danger" title="Evento pendente de monitoramento"><i class="material-icons">more_horiz</i> </span>' : '') 
              }
             },
          ]
      },
      configExportExcel: { activePrompt: false, fileName: "", formats:["xlsx", "csv", "txt"] , 
          cellAutoWidth: true, selectedFormat: "xlsx", 
          headerTitle: ["Placa", "Evento", "Data/Hora Evento", "Ação", "Descrição Ação", "Monitor"], 
          headerVal: ["placa", "evento", "dataHora", "acao", "descricaoAcao", "monitorPanico"] 
      },
      configExportExcelListaVeiculos: { activePrompt: false, fileName: "", formats:["xlsx", "csv", "txt"] , 
          cellAutoWidth: true, selectedFormat: "xlsx", 
          headerTitle: ["Cliente", "CPF/CNPJ", "Marca Veiculo", "Modelo", "Label", "Placa"], 
          headerVal: ["clienteNome", "clienteCPFCNPJ", "marca", "modelo", "labelVeiculo", "placa"] 
      },
      eventoSelecionado: [],      
      efetuandoRequest: false,
      map: null,
      pollingIntervalObj: null,
      dadosDaGrid: [],
      dadosDaGridHistorico: [],
      qtdAvisosPanico: 0,     
      qtdAvisos: 0 ,
      qtdVeiculosMonitorados: 0,
      popupListaVeiculosMonitoraveis: {
        exibir: false,
        listaVeiculosMonitoraveis: []
      },
      popupProcedimento : { 
        exibir: false,
        procedimento: null,
        contato1: null,
        contato2: null,
        contatoPanico1: null,
        contatoPanico2: null,
        contatoPanico3: null
      },
      popupInformacoes : { 
        exibir: false,
        veiculo: null
      },
      popMonitoramento: {
        exibir: false,
        evento: null,
        descricaoAcao: null,
        novoStatus: null ,
        descricaoNovoStatus: null      
      },
      popUpHistoricoEventos: {
        titulo: null,
        exibir: false,
        veiculo: null,
        dataHoraPanicoInicio: null,
        dataHoraPanicoFim: null
      },
      configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "U", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center ml-2 mr-2",
            locale: PortugueseLocale
      },
      dataHoraUltimaAtualizacao: null,
      isPiscar: false,
      publicPath: process.env.BASE_URL,
      itensSelecionados: []
    }
  },  
  created() {
  },
  computed: {
    isPopUpMonitoramentoValido() {
        return this.popMonitoramento.descricaoAcao && this.popMonitoramento.descricaoAcao.length > 0;
    }
  },
  methods: {
   
    onRowSelectedRegistro(linha) {  
      let registro = linha.node.data;    
      this.popupAcoes.exibir = true; 
      this.popupAcoes.evento = JSON.parse(JSON.stringify(registro)); 
    },
    getRowId(params) {
      return params.data.idEvento + params.data.tipo;
     },
    abrirPopUpMonitoramentoLote(novoStatus, descricaoNovoStatus) {
        this.popMonitoramento.exibir = true;        
        this.popMonitoramento.descricaoAcao = null;
        this.popMonitoramento.novoStatus = novoStatus;
        this.popMonitoramento.descricaoNovoStatus = descricaoNovoStatus;
    },
    mostrarNoMapa(evento) {      
      if (evento && !evento.exibidoNoMapa) {
          this.addMarkerAviso(evento);            
          var location = this.$refs.gmc.getLatLng(evento.latitude, evento.longitude);
          this.map.setCenter(location);
          this.map.setZoom(18);
          this.$set(evento, "exibidoNoMapa", true);
      }
    },
    exibirPopUpExportarExcel(){
      this.configExportExcel.activePrompt = true
      this.popUpHistoricoEventos.exibir = false;
      this.configExportExcel.fileName = "Historico de Monitoramento_";
    },
    exportToExcel() {
        import('@/vendor/Export2Excel').then(excel => {
          const list = this.dadosDaGridHistorico;
          const data = this.formatJson(this.configExportExcel.headerVal, list)
          excel.export_json_to_excel({
            header: this.configExportExcel.headerTitle,
            data,
            filename: this.configExportExcel.fileName,
            autoWidth: this.configExportExcel.cellAutoWidth,
            bookType: this.configExportExcel.selectedFormat
          })        
        })
    },
    exibirPopUpExportarListaVeiculosExcel(){
      this.configExportExcelListaVeiculos.activePrompt = true      
      this.popupListaVeiculosMonitoraveis.exibir = false;
      this.configExportExcelListaVeiculos.fileName = "Lista de Veículos Monitorados";
    },
    exportListaVeiculosToExcel() {
        import('@/vendor/Export2Excel').then(excel => {
          const list = this.popupListaVeiculosMonitoraveis.listaVeiculosMonitoraveis;
          const data = this.formatJson(this.configExportExcelListaVeiculos.headerVal, list)
          excel.export_json_to_excel({
            header: this.configExportExcelListaVeiculos.headerTitle,
            data,
            filename: this.configExportExcelListaVeiculos.fileName,
            autoWidth: this.configExportExcelListaVeiculos.cellAutoWidth,
            bookType: this.configExportExcelListaVeiculos.selectedFormat
          })        
        })
    },
    formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {    
          return v[j]
        }))
    },
    pesquisarVeiculo(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                return (c.placa.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
    }, 
    abrirPopUpListaVeiculosMonitoraveis() {
        this.popupListaVeiculosMonitoraveis.exibir = true;
        this.fetchListaVeiculosMonitoraveis();
    },
    exibirEventoSelecionado(tr) {
      if (tr) {
        var location = this.$refs.gmc.getLatLng(tr.latitude, tr.longitude);
        this.map.setCenter(location);
        this.map.setZoom(14);
      }
    },
    abrirPopUpHistoricoPeriodo() {
        this.fetchListaVeiculosMonitoraveis();
        this.popUpHistoricoEventos.exibir = true;        
        this.popUpHistoricoEventos.veiculo = null;
        this.popUpHistoricoEventos.titulo = "Histórico de eventos por período";
        this.popUpHistoricoEventos.dataHoraPanicoInicio = String(new Date().getTime() / 1000);
        this.popUpHistoricoEventos.dataHoraPanicoFim = String(new Date().getTime() / 1000);
        this.fetchHistorico();
    },
    abrirPopUpHistorico(tr) {
        this.popUpHistoricoEventos.exibir = true;
        this.popUpHistoricoEventos.veiculo = tr;
        this.popUpHistoricoEventos.titulo = "Histórico de eventos - " + this.popUpHistoricoEventos.veiculo.placa;
        this.popUpHistoricoEventos.dataHoraPanicoInicio = String(new Date().getTime() / 1000);
        this.popUpHistoricoEventos.dataHoraPanicoFim = String(new Date().getTime() / 1000);
        this.fetchHistorico();
    },
    abrirPopUpMonitoramento(tr, novoStatus, descricaoNovoStatus) {
        this.popMonitoramento.exibir = true;
        this.popMonitoramento.evento = tr;
        this.popMonitoramento.descricaoAcao = null;
        this.popMonitoramento.novoStatus = novoStatus;
        this.popMonitoramento.descricaoNovoStatus = descricaoNovoStatus;
    },
    abrirPopUpInformacoes(tr) {
        this.popupInformacoes.exibir = true;
        this.popupInformacoes.veiculo = tr;
    },
    abrirPopUpProcedimento(tr) {
        this.popupProcedimento.exibir = true;
        this.popupProcedimento.procedimento = tr.procedimentoPanico;
        this.popupProcedimento.contato1 = (tr.hasOwnProperty('contato1') ? tr.contato1 : '');
        this.popupProcedimento.contato2 = (tr.hasOwnProperty('contato2') ? tr.contato2 : '');

        this.popupProcedimento.contatoPanico1 = (tr.hasOwnProperty('contatoPanico1') ? tr.contatoPanico1 : '');
        this.popupProcedimento.contatoPanico2 = (tr.hasOwnProperty('contatoPanico2') ? tr.contatoPanico2 : '');
        this.popupProcedimento.contatoPanico3 = (tr.hasOwnProperty('contatoPanico3') ? tr.contatoPanico3 : '');
    },
    exibirToaster(aviso, tempoExibicao) {
      this.$vs.notify({
        title: aviso.placa, text: aviso.tipoEvento + ' às ' + aviso.dataHoraPanico, time: tempoExibicao,
        iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' 
      });      
    },
    fetchLista() {     
      this.dataHoraUltimaAtualizacao = "Pesquisando ...";
      
      if (this.efetuandoRequest) {        
        return;
      }
      this.isPiscar = false;
      this.efetuandoRequest = true;
      //this.$vs.loading();
      const params = new URLSearchParams();
      params.append('outputFormat', 'JSON');
      params.append('pagina', -1);
      params.append('idUsuarioMonitorPanico', this.$store.state.AppActiveUser.id);

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.efetuandoRequest = false;
          //this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              
              let eventosRetirados = [];
              let eventosNovos = [];

              if (Array.isArray(this.dadosDaGrid) && this.dadosDaGrid.length ) {      
                try {
                    let eventoExiste = false;
                    this.dadosDaGrid.forEach((va) => {                
                        eventoExiste = false;
                        response.data.lista.forEach((vn) => {        
                            if (va.idEvento === vn.idEvento) {                                
                                eventoExiste = true;
                                if (va.panico !== vn.panico) { this.$set(va, "panico", vn.panico); }
                                if (va.ignicao !== vn.ignicao) { this.$set(va, "ignicao", vn.ignicao); }
                                if (va.dataUltimaAtualizacao !== vn.dataUltimaAtualizacao) { this.$set(va, "dataUltimaAtualizacao", vn.dataUltimaAtualizacao); }
                                if (va.tempoParado !== vn.tempoParado) { this.$set(va, "tempoParado", vn.tempoParado); }
                                if (va.isBloqueado !== vn.isBloqueado) { this.$set(va, "isBloqueado", vn.isBloqueado); }
                                if (va.status !== vn.status) { this.$set(va, "status", vn.status); }
                                if (va.statusPanico !== vn.statusPanico) { this.$set(va, "statusPanico", vn.statusPanico); }
                                if (va.tempoSemGPRS !== vn.tempoSemGPRS) { this.$set(va, "tempoSemGPRS", vn.tempoSemGPRS); }
                                if (va.clienteDataHoraUltimoAcesso !== vn.clienteDataHoraUltimoAcesso) { this.$set(va, "clienteDataHoraUltimoAcesso", vn.clienteDataHoraUltimoAcesso); }
                                
                                if (va.dataHoraUltimoMonitoramentoPanico !== vn.dataHoraUltimoMonitoramentoPanico) { this.$set(va, "dataHoraUltimoMonitoramentoPanico", vn.dataHoraUltimoMonitoramentoPanico); /* eslint-disable no-console */ console.log('Atualizando Evento =' + va.idEvento) + 'data hora ultimo monitoramento '}
                                if (va.ultimaVoltagem !== vn.ultimaVoltagem) { this.$set(va, "ultimaVoltagem", vn.ultimaVoltagem); }
                                if (va.usuarioMonitorPanico !== vn.usuarioMonitorPanico) { this.$set(va, "usuarioMonitorPanico", vn.usuarioMonitorPanico); /* eslint-disable no-console */ console.log('Atualizando Evento =' + va.idEvento + ' Usuario Monitor')}
                                if (va.semAlimentacaoExterna !== vn.semAlimentacaoExterna) { this.$set(va, "semAlimentacaoExterna", vn.semAlimentacaoExterna); }
                                if (va.possuiBloqueio !== vn.possuiBloqueio) { this.$set(va, "possuiBloqueio", vn.possuiBloqueio); }

                                if (va.procedimento !== vn.procedimento) { this.$set(va, "procedimento", vn.procedimento); }
                                if (va.contato1 !== vn.contato1) { this.$set(va, "contato1", vn.contato1); }
                                if (va.contato2 !== vn.contato2) { this.$set(va, "contato2", vn.contato2); }
                                if (va.contatoPanico1 !== vn.contatoPanico1) { this.$set(va, "contatoPanico1", vn.contatoPanico1); }
                                if (va.contatoPanico2 !== vn.contatoPanico2) { this.$set(va, "contatoPanico2", vn.contatoPanico2); }
                                if (va.contatoPanico3 !== vn.contatoPanico3) { this.$set(va, "contatoPanico3", vn.contatoPanico3); }       
                                if (va.velocidadeAtual !== vn.velocidadeAtual) { this.$set(va, "velocidadeAtual", vn.velocidadeAtual); }               
                                if (va.enderecoAtual !== vn.enderecoAtual) { this.$set(va, "enderecoAtual", vn.enderecoAtual); }           

                            }
                        });

                        if (!eventoExiste) {
                          eventosRetirados.push(va);
                        }
                    });

                    response.data.lista.forEach((vn) => {   
                        eventoExiste = false;     
                        this.dadosDaGrid.forEach((va) => {                                        
                            if (va.idEvento === vn.idEvento) {
                                eventoExiste = true;
                            }
                        });
                        if (!eventoExiste) {
                          eventosNovos.push(vn);
                        }
                    });

                } catch(erro1){
                  /* eslint-disable no-console */ console.log(erro1);
                }
              } else {      
                  this.$refs.gmc.deleteMarkers();
                  this.dadosDaGrid = response.data.lista;   
                  let tamanhoLista = ( this.dadosDaGrid ? this.dadosDaGrid.length : 0);
                  let exibidos = 0;
                  this.dadosDaGrid.forEach((aviso) => {                                              
                      if (tamanhoLista < 20) {
                        this.addMarkerAviso(aviso);            
                      }
                      exibidos++;
                      if (exibidos > (tamanhoLista - 10)) {                        
                        // Só mostra os 10 ultimos                        
                        this.exibirToaster(aviso, 10000);
                      }
                  });
                  if (tamanhoLista < 20) {
                    this.$refs.gmc.ajustarZoom(this.map);
                  }
              }

              if (eventosRetirados.length > 0) {
                // remover da grid
                eventosRetirados.forEach((vr) => {
                    for (var i = this.dadosDaGrid.length - 1; i >= 0; i--) {                                                                                     
                        if (this.dadosDaGrid[i].idEvento === vr.idEvento) {
                                this.dadosDaGrid.splice(i,1);
                                continue;
                        }
                    }

                    for (var j = this.$refs.gmc.getMarcadores().length - 1; j >= 0; j--) {
                        if (vr.idEvento + '-' + vr.tipoEvento === this.$refs.gmc.getMarcadores()[j].title) {
                                this.$refs.gmc.getMarcadores()[j].setMap(null);
                                this.$refs.gmc.deleteMarker(this.$refs.gmc.getMarcadores()[j]);
                                continue;
                        }
                    }
                });
                // remover o marker
              }

              if (eventosNovos.length > 0) {
                 eventosNovos.forEach((aviso) => {                                        
                    this.addMarkerAviso(aviso);    
                    this.dadosDaGrid.push(aviso);            
                    this.exibirToaster(aviso, -1); //Deixar permanente até ser clicado.
                 });
              }

              this.qtdAvisosPanico = response.data.qtdAvisosPanico;                              
              this.qtdAvisos = response.data.qtdAvisos;  
              this.qtdVeiculosMonitorados = response.data.qtdVeiculosMonitoraveis;  

              if (this.dadosDaGrid.length > 0) { 
                this.playSoundAviso();
              }

              this.dataHoraUltimaAtualizacao = generico.formatarTimestampParaDMYHMS(new Date().getTime());
              this.isPiscar = true;
          }
      })
      .catch((error) => { 
          this.efetuandoRequest = false;
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
    },
    enviarMonitoramento() {
      this.$vs.loading();
      
      if (this.popMonitoramento.novoStatus === 3 || this.popMonitoramento.novoStatus === 4) {
        this.popupAcoes.exibir = false;
      }

      const params = new URLSearchParams();
      params.append('outputFormat', 'JSON');

      params.append('panico', this.popMonitoramento.novoStatus);
      params.append('descricaoAcao', this.popMonitoramento.descricaoAcao);
      if (this.popMonitoramento.evento) {
        params.append('codigoModulo', this.popMonitoramento.evento.codigoModulo);
        params.append('marcaModulo', this.popMonitoramento.evento.marcaModulo);
        params.append('idVeiculo', this.popMonitoramento.evento.idVeiculo);
        params.append('idEvento', this.popMonitoramento.evento.idEvento);
      } else {
        var idsEventos = null;        

        for (var i= 0; i < this.itensSelecionados.length; i++){
            if (idsEventos === null) {
                idsEventos = new String();
                idsEventos = this.itensSelecionados[i].idEvento;                
            } else {
                idsEventos = idsEventos + "," + this.itensSelecionados[i].idEvento;
            }
        }
        params.append('idsEventos', idsEventos);
      }
      params.append('idUsuario', this.$store.state.AppActiveUser.id);
      
      axios.post("/AlterarStatusPanicoVeiculo", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.popMonitoramento.exibir = false;
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
             this.$vs.notify({
                title: "Mensagem", text: this.popMonitoramento.descricaoNovoStatus + ' registrado com sucesso.',
                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
              this.fetchLista();                              
          }
      })
      .catch((error) => { 
          this.popMonitoramento.exibir = false;
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
      })  
    },
    fetchHistorico() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('outputFormat', 'JSON');
      if (this.popUpHistoricoEventos.veiculo) {
        params.append('idVeiculo', this.popUpHistoricoEventos.veiculo.idVeiculo);
      }
      if (this.popUpHistoricoEventos.dataHoraPanicoInicio) {
        params.append('dataHoraPanicoInicio', this.popUpHistoricoEventos.dataHoraPanicoInicio*1000);
      }

      if (this.popUpHistoricoEventos.dataHoraPanicoFim) {
        params.append('dataHoraPanicoFim', this.popUpHistoricoEventos.dataHoraPanicoFim*1000);
      }

      axios.post("/ListarHistoricoPanicoVeiculo", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGridHistorico = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    bloquear(tr) {         
        this.eventoSelecionado =tr;   
        this.$vs.dialog({
            type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja bloquear o veículo ' +  tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.bloquearRequest
        });      

    },
    bloquearRequest() {
        this.enviarBloqueioDesbloqueio(this.eventoSelecionado, 'BLOQUEAR');
    },
    desbloquear(tr) {            
        this.eventoSelecionado = tr;   
        this.$vs.dialog({
            type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja desbloquear o veículo ' +  tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.desbloquearRequest
        });            
    },
    desbloquearRequest() {
        this.enviarBloqueioDesbloqueio(this.eventoSelecionado, 'DESBLOQUEAR');
    },
    enviarBloqueioDesbloqueio(evento, acao) {
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('outputFormat', 'JSON');

      params.append('serialModulo', evento.serialModulo);
      params.append('operadoraGSM', evento.operadoraGSM);
      params.append('codigoModulo', evento.codigoModulo);
      params.append('marcaModulo', evento.marcaModulo);
      params.append('tipoComandoBloqueioDesbloqueio', acao);
      params.append('idUsuario', this.$store.state.AppActiveUser.id);

      axios.post("/EnviarComandoBloqueioDesbloqueio", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.popMonitoramento.exibir = false;
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
            if (response.data.codigo === 0) {
              this.$vs.notify({
                title: "Mensagem", text: response.data.mensagem,
                iconPack: 'feather', icon: 'icon-info-circle', color: 'success' });
            }  else {
             this.$vs.notify({
                title: "Mensagem", text: response.data.mensagem,
                iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            }              
          }
      })
      .catch((error) => { 
          this.popMonitoramento.exibir = false;
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
      })  
    },
    addMarkerAviso(aviso) {
      var location = this.$refs.gmc.getLatLng(aviso.latitude, aviso.longitude);
      
      var marker1 = new window.MarkerWithLabel({
         position: location,
          labelContent: aviso.placa + '<br/>' + aviso.dataHoraPanico + '<br/>' + aviso.condutor,
          title: aviso.idEvento + '-' + aviso.tipoEvento,
          map: this.map,                   
          labelAnchor: new window.google.maps.Point(60, 0),       
          labelStyle: {opacity: 1} ,
          labelClass: 'labelAvisos',
          icon: { url: this.publicPath + 'imagens/iconesAvisos/dot' + aviso.tipo + '.png'}   
      });  
                  
      var marcadores = this.$refs.gmc.getMarcadores();
      marcadores.push(marker1);      
      
      return marker1;
    },
    playSoundAviso () {      
      var audio = new Audio(require("../../../assets/sons/panico.mp3"));
      audio.play();      
    },
    fetchListaVeiculosMonitoraveis() {     
     const params = new URLSearchParams();
      params.append('outputFormat', 'JSON');
      params.append('pagina', -1);
      params.append('idUsuarioMonitorPanico', this.$store.state.AppActiveUser.id);
      params.append('acao', "LISTA_VEICULOS_MONITORAVEIS");

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.popupListaVeiculosMonitoraveis.listaVeiculosMonitoraveis = response.data;         
          }
      })
      .catch(() => { 
          this.$vs.loading.close();
      })    
                        
    },
  },
  mounted() {
     this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
     this.configuracaoGrid.gridApi.sizeColumnsToFit();    
     this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: new window.google.maps.LatLng(-15, -48),       
        gestureHandling: 'greedy',
        zoom: 5,
        fullscreenControl:true,
        fullscreenControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_TOP            
        },
        streetViewControl: false,
        zoomControl: true,
        zoomControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_TOP
        },
        mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU
        }
    });

    this.fetchLista();
    this.pollingIntervalObj = setInterval(() => {     
         this.fetchLista();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.pollingIntervalObj);
  }
}

</script>
<style lang="scss">  
.con-vs-dialog {    
    .vs-dialog {
      max-width: 1000px;      
    }
}

.con-vs-popup { 
    .vs-popup {
        min-width: 800px;
        max-width: 1000px;      
    }
}
</style>

<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 370px);        
    position: relative;    
  }

  .map50{    
    height: calc(30vh - 25px);
    width: calc(100vw - 155px);       
  }
  .map100{
    height: calc(100vh - 25px);
  }

  .blink_me {
    animation: blinker 1s linear 4;
  }

  @keyframes blinker {  
    50% { opacity: 0; }
  }

  .tabelaComScroll {                  
      height: calc(70vh - 50px);
      width: calc(100vw - 112px);      
      overflow: auto;   
   } 
</style>

<style scoped>

   .tabelaNotificacoesComScroll {      
      overflow: auto;  
      height: calc(100vh - 400px);
      position: relative;      
  }
</style>